import React from 'react'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'
import { Container, PagePadding, Cols } from '../styles'
import ContactForm from '../components/ContactForm'
import styled from 'styled-components'

const ContactUsPage = () => {
    return (
        <Layout>
            <SEO
                title="Free & Confidential Case Review | Personal Injury Lawyer | South Bay | LA"
                metaDesc="Contact Us | At The Law Office of Craig Charles, we have the depth of experience needed to handle the most complex litigation."
            />
            <PageBanner title="Confidential Case Review" subtitle="Child Sex Abuse Law Firm" />
            <Container>
                <PagePadding>
                    <TextBlock>
                        <h2>Consult with us to evaluate your case</h2>
                        <p>
                            If you or a loved one is the victim of child sex abuse, no matter how long ago the abuse occurred, know that Craig Charles Law is here to help you through the process.
                            Every inquiry submitted on this website is highly confidential. Additionally, your submission will be answered directly by one of our experienced sex abuse attorneys 
                            within 24 hours. Our job is to help each victim achieve justice, compensation and closure. 
                        </p>
                        <p>
                            Victims who do not wish to submit an online inquiry can call our office directly to speak with a live person at 
                            424-343-0660.
                        </p>
                    </TextBlock>
                    <Cols twoColsSidebar>
                        <div className="col" style={{marginBottom: "32px"}}>
                            <ContactForm />
                        </div>
                        <div className="col">
                            <h2 className="h2 sm">
                                Office Locations
                            </h2>
                            <OfficeAddress>
                                <li>
                                    <a href="https://www.google.com/maps/place/Craig+Charles+Law/@33.9186078,-118.3951546,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2b1bcc63ac4db:0x89385384b7fac46b!8m2!3d33.9186078!4d-118.392937" target="_blank" rel="noreferrer noopener">
                                        El Segundo Office
                                    </a>
                                </li>
                                <li>(424) 343-0660</li>
                                <li>info@craigcharleslaw.com</li>
                                <li>1960 E Grand Ave, Suite 720</li>
                                <li>El Segundo, CA 90245</li>
                            </OfficeAddress>
                            <OfficeAddress>
                                <li>
                                    <a href="https://www.google.com/maps/place/Craig+Charles+Law/@34.09037,-118.3902177,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2bea170777473:0x96bfc2cbc6ad3a2!8m2!3d34.09037!4d-118.388029" target="_blank" rel="noreferrer noopener">
                                        West Hollywood Office
                                    </a>
                                </li>
                                <li>(424) 343-0660</li>
                                <li>info@craigcharleslaw.com</li>
                                <li>9000 Sunset Blvd, Suite 710</li>
                                <li>West Hollywood, CA 90069</li>
                            </OfficeAddress>
                            <OfficeAddress>
                                <li>
                                    San Diego Office
                                </li>
                                <li>(424) 343-0660</li>
                                <li>info@craigcharleslaw.com</li>
                                <li>5755 Oberlin Drive, Suite 301</li>
                                <li>San Diego, CA 92121</li>
                            </OfficeAddress>                            
                        </div>
                    </Cols>
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default ContactUsPage

const OfficeAddress = styled.ul`
    margin: 32px 0;

    li {
        margin-bottom: 12px;
        font-size: 17px;
        font-weight: 300;

        &:first-child {
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0.031em;
            text-transform: uppercase;
        }
    }
`;

const TextBlock = styled.div`
    padding: 24px 0 40px;

    @media(min-width: 900px) {
        width: 80%;
    }

    h2 {
        @extend .h2;
    }

    p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.618;
    }
`;
